<template>
    <div class="grid grid-cols-2">
        <div><Header :title="$tc('dashboard', 1)" heading="h1" /></div>
        <div><SwitchVerticalIcon @click="runJob()" class="flex-shrink-0 h-10 w-10 text-gray-600 hover:text-gray-400" aria-hidden="true" />Run Job</div>
    </div>

    <section>
        <div class="grid grid-cols-2 gap-4 mt-10 py-10">
            <table class="min-w-full divide-y divide-fibonaki-border">
                <thead>
                    <tr>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('item', 2) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('number', 1) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('organisation', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.organisations }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('ean', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.eans }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('invoice', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.invoices }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('unchecked', 1) }} {{ $tc('invoice', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.invoices - dashboard_data.checked_invoices }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('checked', 1) }} {{ $tc('invoice', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.checked_invoices }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="min-w-full divide-y divide-fibonaki-border">
                <thead>
                    <tr>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('item', 2) }}</th>
                        <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('number', 1) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('user', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.users }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('contact', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.contacts }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('degressivity', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.degressivities }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('product', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.products }}</td>
                    </tr>
                    <tr>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('meter', 2) }}</td>
                        <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.meters }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    <section>
        <table class="min-w-full divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('item', 2) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('number', 1) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('transmission_tariff', 2) }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.all_transmissions }}</td>
                </tr>
                <tr>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('distribution_tariff', 2) }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.all_distributions }}</td>
                </tr>
                <tr>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $tc('federal_contribution_tariff', 2) }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ dashboard_data.all_federal_contributions }}</td>
                </tr>
            </tbody>
        </table>
    </section>

    <section>
        <div class="grid grid-cols-3 gap-4 mt-10 py-10">
            <div>
                <Header :title="$tc('transmission_tariff', 2)" heading="h4" />
                <div class="mt-10" style="max-height: 500px; overflow: auto">
                    <div v-for="{ name, value, index } in dashboard_data.tmt_array" :key="index">
                        {{ name }} : <b>{{ value }}</b>
                    </div>
                </div>
            </div>
            <div>
                <Header :title="$tc('distribution_tariff', 2)" heading="h4" />
                <div class="mt-10" style="max-height: 500px; overflow: auto">
                    <div v-for="{ name, value, index } in dashboard_data.dmt_array" :key="index">
                        {{ name }} : <b>{{ value }}</b>
                    </div>
                </div>
            </div>
            <div>
                <Header :title="$tc('federal_contribution_tariff', 2)" heading="h4" />
                <div class="mt-10" style="max-height: 500px; overflow: auto">
                    <div v-for="{ name, value, index } in dashboard_data.fcmt_array" :key="index">
                        {{ name }} : <b>{{ value }}</b>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { SwitchVerticalIcon } from '@heroicons/vue/outline';
    import DashboardDataService from '@/services/DashboardDataService';

    export default {
        components: {
            SwitchVerticalIcon,
        },
        created() {
            this.$store.dispatch('dashboard/fetchDashboardData');
        },
        computed: {
            dashboard_data() {
                return this.$store.getters['dashboard/dashboard_data'];
            },
        },
        methods: {
            runJob() {
                DashboardDataService.triggerJob();
            },
        },
    };
</script>
